import { useEnv } from '#app/utils/env.js';
import { useOptionalUser } from '#app/utils/user.js';
import { useLocation } from '@remix-run/react';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { useEffect } from 'react';

export const analytics = new AnalyticsBrowser();

export const SegmentTracker = () => {
	const env = useEnv();

	useEffect(() => {
		if (!env.SEGMENT_KEY) {
			console.warn('Analytics is not configured');
			return;
		}

		analytics.load(
			{ writeKey: env.SEGMENT_KEY },
			{
				obfuscate: true,
			},
		);
	}, [env.SEGMENT_KEY]);

	if (!env.SEGMENT_KEY) {
		return null;
	}

	return (
		<>
			<IdentifyTracker />
			<PageTracker />
		</>
	);
};

function IdentifyTracker() {
	const user = useOptionalUser();

	useEffect(() => {
		if (!user?.id) return;

		analytics.identify(user.id, {
			id: user.id,
			email: user.email,
			firstName: user.firstName,
			lastName: user.lastName,
			avatar: user.avatar?.url,
		});
	}, [
		user?.avatar?.url,
		user?.email,
		user?.firstName,
		user?.id,
		user?.lastName,
	]);

	return null;
}

function GetPageContextReferrer(search: string) {
	const searchParams = new URLSearchParams(search);
	const impactClickId = searchParams.get('irclickid');

	if (impactClickId) {
		return {
			type: 'impactRadius',
			id: impactClickId,
		};
	}

	return undefined;
}

function PageTracker() {
	const location = useLocation();

	useEffect(() => {
		const category = location.pathname.startsWith('/app') ? 'app' : 'site';
		const referrer = GetPageContextReferrer(location.search);

		analytics.page(
			category,
			location.pathname,
			{},
			{
				context: {
					referrer,
				},
			},
		);
	}, [location.pathname, location.search]);

	return null;
}
